@import "color.scss";


//Общие стили для Формы
.input-wrap {
  position: relative;
  display: inline-block;
  max-width: 340px;
  width: 100%;
}

.input-label {
  position: absolute;
  width: fit-content;
  padding: 0 4px;
  top: 16px;
  left: 16px;
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing: -0.01em;
  color: rgba(0, 0, 0, 0.7);
  background-color: $card-bg;
  pointer-events: none;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
}

.form-input {
  width: 100%;
  height: 56px;
  padding: 15px 12px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  resize: none;
  font-size: 1.8rem;
  line-height: 2.4rem;
  transition: .3s;
  &::placeholder {
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: rgba(0, 0, 0, 0.7);
  }

  &:hover {
    border: 1px solid $text-color;
    &::placeholder {
      color: rgba(0, 0, 0, 0.6);
    }
  }

  &:disabled {
    border: 1px solid rgba(0, 0, 0, 0.23);
    &::placeholder {
      color: rgba(0, 0, 0, 0.27);
    }
  }

  &:focus ~ .input-label,
  &:not(:placeholder-shown) ~ .input-label {
    transform: translateY(-28px) scale(0.85);
    font-size: 1.6rem;
  }

  &:not(:placeholder-shown) ~ .input-label {
    color: $text-color;

  }
}

.upload-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 340px;
  width: 100%;
  height: 56px;
  border-radius: 4px;
  border: 1px dashed #7E7E7E;

  .upload-icon {
    background-image: url("../img/upload-icon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    margin-right: 15px;
  }
}

.upload-name {
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: rgba(0, 0, 0, 0.7);
}

.form-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 340px;
  width: 100%;
  height: 56px;
  border-radius: 4px;
}


