@import "color.scss";
@import "container.scss";
@import "font.scss";
@import "header.scss";
@import "main.scss";
@import "buttons.scss";
@import "title.scss";
@import "form.scss";
@import "footer.scss";
@import "modals.scss";
@import "plug.scss";

* {
  outline: none;
  box-sizing: border-box;

  &:after,
  &:before {
    transition: inherit;
  }
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}


body {
  font-family: "FF Unit Pro Regular", "FF Unit Pro Medium";
}



:root {
  font-size: 10px;
  font-weight: 300;
}

h1, h2, h3, h4, h5, h6, p, ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: $text-color;
  cursor: pointer;
  transition: .3s;
}

input,
button,
textarea {
  outline: none;
  background: none;
  border: none;
  padding: 0;
  color: $text-color;
  cursor: pointer;
  transition: .3s;
  outline: 0;
}

.logo {
  display: flex;
  align-items: center;

}

.logo-img {
  object-fit: contain;
  width: 145px;
  height: 65px;
  @media screen and (max-width: 767px) {
    width: 90px;
    height: 40px;
  }
}


.logo-text {
  position: relative;
  width: 290px;
  margin-left: 40px;
  font-size: 1.7rem;
  line-height: 2.1rem;
  color: rgba(255, 255, 255, 0.6);
  &:before {
    content: '';
    position: absolute;
    display: block;
    height: 50px;
    width: 0;
    top: 50%;
    left: -25px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    transform: rotate(15deg) translate(0, -50%);
    @media screen and (max-width: 767px) {
      height: 30px;
      left: -18px;
    }
  }
  @media screen and (max-width: 767px) {
    width: 160px;
    margin-left: 24px;
    font-size: 10px;
    line-height: 1.3rem;
  }
  @media screen and (max-width: 375px) {
    display: none;
  }
}


.menu-item {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: -10px;
    left: 0;
    background-color: $red-color;
    transition: .3s;
  }

  &:hover {
    &:before {
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 56px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.menu-link {
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 1.8rem;
  color: $white-color;
  @media screen and (max-width: 767px) {
    color: $text-color;
  }
}


//Точки для адаптива
@media screen and (max-width: 1600px) {

}
@media screen and (max-width: 1400px) {

}
@media screen and (max-width: 1199px) {

}
@media screen and (max-width: 767px) {

}
@media screen and (max-width: 425px) {

}

