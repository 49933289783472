@import "color.scss";

//Общие стили для кнопок

.btn {

}

.btn-primary {
  font-size: 1.8rem;
  font-weight: 500;
  color: $white-color;
  background-color: $btn-color;
  box-shadow: 0 4px 12px rgba(255, 46, 46, 0.46);
  border-radius: 4px;
  text-transform: uppercase;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    background-color: rgba(255, 255, 255, 0.3);
    transition: .5s;

  }
  &:hover {
    background-color: $hover-color;
  }

  &:active {
    &:before {
      transform: translate(-50%, -50%) scale(3);
    }
  }

  &:disabled {
    background-color: $gray-bg;
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
  }

}

.btn-accent {


}


.prev-btn,
.next-btn {
  position: relative;
  width: 56px;
  height: 56px;
  border: 2px solid $red-color;
  border-radius: 5px;
  transition: .3s;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    width: 22px;
    height: 22px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:hover {
    background-color: $red-color;
  }
}

.prev-btn {
  &:before {
    background-image: url("../img/arrow-prew.svg");
  }
  &:hover {
    &:before {
      background-image: url("../img/arrow-prew-hover.svg");
    }
  }
}

.next-btn {
  &:before {
    background-image: url("../img/arrow-next.svg");
  }
  &:hover {
    &:before {
      background-image: url("../img/arrow-next-hover.svg");
    }
  }
}

