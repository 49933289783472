@import "color.scss";


.footer {
  background-color: $card-bg;
  &__wrapper {
    padding: 30px 20px;
    @media screen and (max-width: 1199px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
  .bottom-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 1199px) {
      flex-direction: column;
      align-items: flex-end;
    }
    @media screen and (max-width: 767px) {
      align-items: center;
    }
    &:first-child {
      margin-bottom: 20px;
      @media screen and (max-width: 1199px) {
        margin-bottom: 0;
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }
    &:last-child {
      position: absolute;
      flex-direction: column;
      align-items: flex-end;
      top: 0;
      right: 20px;
      @media screen and (max-width: 767px) {
        position: relative;
        align-items: center;
        right: auto;
      }
    }
  }



  .logo {
    @media screen and (max-width: 767px) {
      order: 2;
    }
  }
  .logo-img {
    @media screen and (max-width: 1199px) {
      width: 100px;
      height: 40px;
    }
  }
  .logo-text {
    color: $text-color;
    @media screen and (max-width: 1199px) {
      font-size: 1.6rem;
      line-height: 1.3rem;
    }
    @media screen and (max-width: 767px) {
      width: 230px;
    }


    &:before {
      background-color: $text-color;
      @media screen and (max-width: 1199px) {
        height: 35px;
      }
    }
  }

  .navigate-mobile {
    display: flex;
    max-width: 555px;
    width: 100%;
    @media screen and (max-width: 1199px) {
      display: none;
    }

    @media screen and (max-width: 767px) {
      display: flex;
      flex-wrap: wrap;
      order: 1;
      max-width: 300px;
    }
    .menu-item {
      height: fit-content;
      @media screen and (max-width: 767px) {
        margin-bottom: 35px;
      }
    }
  }
  .menu {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
  }
  .menu-item {
    height: fit-content;
  }
  .menu-link {
    color: $text-color;
  }

  }
  .copyright,
  .dom  {
    color: rgba(148, 148, 148, 0.9);
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  .copyright {
    @media screen and (max-width: 767px) {
      margin-top: 20px;
      margin-bottom: 35px;
      text-align: center;
    }
  }


.widget {
  position: fixed;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  right: 20px;
  bottom: 30px;
  width: 48px;
  max-height: 48px;
  padding: 8px;
  border-radius: 4px;
  background-color: $red-color;
  z-index: 20;
  overflow: hidden;
  transition: .5s;
  @media screen and (max-width: 767px) {
    display: flex;
    right: -80px;
  }
  &.active {
    max-height: 500px;
    background-color: $tab-bg;
  }

  &.show {
    right: 20px;
  }

  &__btn {
    position: relative;
    width: 32px;
    min-height: 32px;
    margin-bottom: 5px;
    outline: none;
    transition: .3s;

    &:before,
    &:after {
      content: '';
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      width: 50%;
      height: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: .3s;
    }

    &:before {
      background-image: url("../img/phone.svg");
    }
    &:after {
      background-image: url("../img/close.svg");
      opacity: 0;
    }

    &.active {
      &:before {
        opacity: 0;
      }
      &:after {
        opacity: 1;
      }
    }
  }

  &__item {
    position: relative;
    width: 32px;
    min-height: 32px;
    margin-top: 8px;
    border-radius: 7px;
    background-color: $section-bg;
    &:before {
      content: '';
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      width: 50%;
      height: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .widget-phone {
    &:before {
      background-image: url("../img/phone.svg");
    }
  }

  .widget-telegram {
    &:before {
      background-image: url("../img/telegram.svg");
    }
  }

  .widget-viber {
    &:before {
      background-image: url("../img/viber.svg");
    }
  }

  .widget-whatsapp {
    &:before {
      background-image: url("../img/whatsapp.svg");
    }
  }

  .widget-email {
    &:before {
      background-image: url("../img/mail.svg");
    }
  }
}


.top-scroll {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: fixed;
  bottom: 30px;
  right: -100%;
  z-index: 20;
  transition: .3s;
  &:after {
    content: '';
    position: absolute;
    background-image: url("../img/arrow-top.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 22px;
    height: 22px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &.active {
    right: 20px;
  }
  @media screen and (max-width: 767px) {
    width: 48px;
    height: 48px;
  }
}