@import "color.scss";

//Общие стили для заголовков
h1.title {
  font-size: 4rem;
  font-weight: 600;
  text-transform: uppercase;
}

h2.title {
  font-size: 3.2rem;
  font-weight: 500;
  text-transform: uppercase;
}

h3.title {
  font-size: 2.8rem;
  line-height: 3.2rem;
  font-weight: 500;
}