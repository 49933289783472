@import "color.scss";

.main {
  position: relative;
  background-color: $section-bg;
  width: 100%;
  height: 935px;
  padding-top: 265px;
  z-index: 0;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    height: 100vh;
    padding-top: 85px;
  }
  @media screen and (max-width: 375px) {

  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 190px;
    @media screen and (max-width: 1199px) {
      padding-bottom: 130px;
    }
    @media screen and (max-width: 767px) {
      padding-bottom: 20px;
    }
    @media screen and (max-width: 425px) {
      padding-bottom: 25px;
    }
  }

  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    overflow: hidden;
    @media screen and (max-width: 767px) {
      height: calc(100vh - 170px);
    }

    @media screen and (max-width: 425px) {
      height: calc(100vh - 180px);
    }


    .bg-wrapper {
      position: absolute;
      width: 695px;
      height: 695px;
      right: 29%;
      top: 27%;
      @media screen and (max-width: 1600px) {
        right: 20%;
      }
      @media screen and (max-width: 1199px) {
        right: auto;
        left: 20%;
      }
      @media screen and (max-width: 767px) {
        width: 390px;
        height: 390px;
        top: 50%;
        left: -10%;
      }
      @media screen and (max-width: 425px) {
        top: 50%;
        left: -20%;
      }
      &:before {
        content: '';
        position: absolute;
        background-image: url(../img/decor-1.png);
        background-size: contain;
        background-repeat: no-repeat;
        width: 201%;
        height: 201%;
        top: -76%;
        left: -4%;
        z-index: -5;
      }
    }

    .bg-men {
      position: absolute;
      object-fit: contain;
      width: 100%;
      height: 100%;
      z-index: 5;
      @media screen and (max-width: 767px) {
        z-index: 10;
      }
    }
  }

  &__text-1 {
    max-width: 585px;
    padding: 35px 40px 50px;
    background-color: $btn-color;
    clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%);
    color: $white-color;
    font-size: 3.2rem;
    line-height: 3.6rem;
    border-radius: 5px;
    @media screen and (max-width: 1199px) {
      max-width: 500px;
      font-size: 2.6rem;
      line-height: 3rem;
      padding: 55px 40px 50px 40px;
    }
    @media screen and (max-width: 767px) {
      max-width: 340px;
      padding: 20px 10px 30px 40px;
      clip-path: polygon(7% 0%, 100% 0%, 93% 100%, 0% 100%);
      font-size: 1.8rem;
      line-height: 2rem;
    }
  }
  &__text-2 {
    width: 510px;
    padding: 25px 80px 20px 30px;
    margin-top: -30px;
    background-color: $text-color;
    clip-path: polygon(3% 0%, 100% 0%, 97% 100%, 0% 100%);
    color: $white-color;
    font-size: 2.8rem;
    line-height: 3.5rem;
    border-radius: 5px;
    z-index: 5;
    @media screen and (max-width: 1199px) {
      position: relative;
      width: 450px;
      padding: 20px 60px 15px 40px;
      font-size: 2.5rem;
      line-height: 3.5rem;
      margin: 0;
    }
    @media screen and (max-width: 767px) {
      max-width: 290px;
      padding: 10px 40px 50px 30px;
      margin-top: -20px;
      clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%);
      font-size: 1.6rem;
      line-height: 1.9rem;
    }
  }

  &__desc {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 370px;
    margin-top: auto;
    margin-left: 40px;
    z-index: 10;
    @media screen and (max-width: 1400px) {
      margin-left: 80px;
    }
    @media screen and (max-width: 767px) {
      max-width: 240px;
      margin-left: 60px;
    }
    @media screen and (max-width: 375px) {
      max-width: 210px;
    }
  }

  &__desc-text {
    position: relative;
    font-size: 2rem;
    line-height: 2.7rem;
    color: $white-color;
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
      width: 100%;
      font-size: 1.6rem;
      line-height: 2rem;
    }
    &:before {
      content: '';
      position: absolute;
      background-image: url("../img/down-arrow.svg");
      background-repeat: no-repeat;
      background-size: contain;
      width: 50px;
      height: 90px;
      left: -60px;
      top: 20px;
      animation: 1.5s linear infinite rotate;
    }
  }
  @keyframes  rotate {
    0% {
      transform: rotate(15deg);
    }
    50% {
      transform: rotate(-15deg);
    }
    100% {
      transform: rotate(15deg);
    }
  }



  &__desc-btn {
    width: 340px;
    height: 56px;
    margin-top: 20px;
    font-size: 1.8rem;
    line-height: 2.4rem;
    @media screen and (max-width: 767px) {
      width: 100%;
      height: 48px;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }
}

.advantage {
  padding-top: 75px;
  padding-bottom: 100px;
  @media screen and (max-width: 1199px) {
    padding-top: 55px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 767px) {
    padding-top: 20px;
    padding-bottom: 30px;
  }

  &__subtitle {
    font-size: 1.8rem;
    line-height: 4rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.4);
  }

  .title {
    margin-top: 14px;
    margin-bottom: 40px;
    @media screen and (max-width: 1199px) {
      margin-bottom: 55px;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 14px;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__list-item {
    width: 19.5%;
    height: 300px;
    padding: 20px 16px;
    background-color: $card-bg;
    border-radius: 4px;

    @media screen and (max-width: 1199px) {
      width: 32.5%;
      margin-bottom: 10px;
      &:last-child {
        width: 66.3%;
      }
    }
    @media screen and (max-width: 767px) {
      width: 49%;
      height: 325px;
      &:last-child {
        width: 100%;
        height: 210px;
      }
    }
    @media screen and (max-width: 375px) {
      padding: 20px 10px;
    }

    .item-img {
      display: block;
      width: 48px;
      height: 48px;
      object-fit: contain;
    }

    .item-title {
      display: block;
      font-size: 2.4rem;
      margin: 16px 0;
      @media screen and (max-width: 767px) {
        font-size: 1.8rem;
        line-height: 2.8rem;
      }
    }

    .item-desc {
      font-size: 1.8rem;
      line-height: 2.4rem;
      font-weight: 400;
      letter-spacing: -0.01em;
      @media screen and (max-width: 767px) {
        font-size: 1.6rem;
        line-height: 2rem;
      }
      @media screen and (max-width: 375px) {
        font-size: 1.4rem;
      }
    }

  }
}


.services {
  padding: 75px 0;
  background-color: $section-bg;
  @media screen and (max-width: 767px) {
    padding: 35px 0;
  }

  .title {
    text-transform: uppercase;
    color: $white-color;
    margin-bottom: 60px;
    @media screen and (max-width: 1199px) {
      margin-bottom: 50px;
    }
    @media screen and (max-width: 767px) {
      text-align: center;
      font-size: 3.2rem;
      line-height: 4rem;
      margin-bottom: 25px;
    }
    @media screen and (max-width: 425px) {
      font-size: 2.8rem;
    }
  }

  &__wrapper {
    display: flex;
    height: 655px;
    @media screen and (max-width: 1199px) {
      height: 680px;
      justify-content: space-between;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
      height: fit-content;
    }
  }


  &__tabs {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 435px;
    padding: 25px 20px;
    margin-right: 65px;
    border-radius: 8px;
    background-color: $tab-bg;
    transition: .3s;

    @media screen and (max-width: 1199px) {
      margin-right: 0;
      flex-basis: 48%;
      max-width: none;
      height: fit-content;
    }
    @media screen and (max-width: 767px) {
      max-width: 320px;
      max-height: 130px;
      padding: 0 15px;
      overflow: hidden;
      &.active {
        max-height: fit-content;
      }
    }

    .tab-item {
      position: relative;
      display: flex;
      padding: 24px 50px;
      font-weight: 500;
      font-size: 2rem;
      line-height: 2.4rem;
      color: $white-color;
      transition: .3s;
      cursor: pointer;
      &:last-child {
        &:after {
          display: none;
        }
      }
      @media screen and (max-width: 1199px) {
        padding: 15px 10px 15px 50px;
      }
      @media screen and (max-width: 767px) {
        min-height: 130px;
        align-items: center;
        padding: 15px 35px 15px 0;
        &:before {
          display: none;
        }
      }
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        left: 20px;
        top: 30px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.2);
        transition: .3s;
      }
      &:after {
        content: '';
        position: absolute;
        width: 320px;
        height: 1px;
        left: 0;
        bottom: 0;
        background-color: rgba(227, 227, 227, 0.1);
      }

      &.active {
        background-color: $section-bg;
        border-radius: 4px;
        @media screen and (max-width: 767px) {
          order: -1;
          background-color: transparent;
        }
        &:before {
          background-color: $red-color;
        }
        &:after {
          display: none;
          @media screen and (max-width: 767px) {
            display: block;
          }
        }
      }
    }
  }

  .tab-btn {
    position: absolute;
    display: none;
    width: 24px;
    height: 24px;
    top: 55px;
    right: 15px;
    border-radius: 4px;
    background-color: $section-bg;
    @media screen and (max-width: 767px) {
      display: block;
    }
    &:before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      border-bottom: 2px solid $white-color;
      border-right: 2px solid $white-color;
      top: 6px;
      left: 50%;
      transform: translate(-50%, 0) rotate(45deg);
      transition: .3s;
    }
    &.active {
      &:before {
        transform: translate(-50%, 0) rotate(225deg);
        top: 9px;
      }
    }
  }


  &__desc {
    display: flex;
    flex-direction: column;
    max-width: 640px;
    max-height: 100%;
    height: fit-content;
    .title {
      margin-bottom: 30px;
      @media screen and (max-width: 1199px) {
        font-size: 2.4rem;
      }
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    @media screen and (max-width: 1199px) {
      flex-basis: 48%;
      max-width: none;
    }
    @media screen and (max-width: 767px) {
      margin-top: 25px;
      flex-basis: auto;
      max-width: 320px;
      width: 100%;
    }

    .desc-img {
      width: 550px;
      height: 290px;
      margin-bottom: 30px;
      object-fit: cover;
      border-radius: 8px;
      @media screen and (max-width: 1199px) {
        width: 100%;
        height: 190px;
      }
    }

    .desc-text {
      height: 100%;
      max-height: 145px;
      overflow-x: auto;
      margin-bottom: 30px;
      font-size: 2rem;
      line-height: 2.4rem;
      font-weight: 400;
      letter-spacing: -0.01em;
      color: $white-color;
      white-space: pre-wrap;
      @media screen and (max-width: 1199px) {
        margin-bottom: 30px;
      }
      @media screen and (max-width: 767px) {
        font-size: 1.8rem;
      }
    }
  }

  &__desc-btn {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: auto;
    @media screen and (max-width: 1199px) {
      flex-direction: column;
    }
    @media screen and (max-width: 767px) {
      max-width: 320px;
    }

    .btn-detailed {
      position: relative;
      display: flex;
      align-items: center;
      width: 320px;
      height: 56px;
      padding: 0 22px;
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 2.4rem;
      color: $red-color;
      text-transform: uppercase;
      border: 2px solid $red-color;
      border-radius: 5px;
      transition: .3s;
      @media screen and (max-width: 1199px) {
        margin-bottom: 18px;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        font-size: 1.6rem;
      }
      @media screen and (max-width: 375px) {
        font-size: 1.4rem;
      }
      &:after {
        content: '';
        position: absolute;
        background-image: url("../img/arrow-next.svg");
        background-size: contain;
        background-repeat: no-repeat;
        width: 22px;
        height: 22px;
        top: 50%;
        right: 20px;
        transform: translate(0, -50%);
        transition: .3s;
      }
      &:hover {
        background-color: $hover-color;
        border: 2px solid $hover-color;
        color: $white-color;
        &:after {
          background-image: url("../img/arrow-next-hover.svg");
          transition: .3s;
        }
      }

      &:focus {
        background-color: $red-color;
        border: 2px solid $red-color;
        color: $white-color;
        &:after {
          background-image: url("../img/arrow-next-hover.svg");
        }

      }
    }

    .btn-price {
      position: relative;
      display: flex;
      align-items: center;
      width: 300px;
      height: 56px;
      padding: 0 35px 0 85px;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2.4rem;
      color: $white-color;
      text-transform: uppercase;
      border: 2px solid $white-color;
      border-radius: 5px;
      @media screen and (max-width: 1199px) {
        width: 320px;
      }
      @media screen and (max-width: 767px) {
        max-width: 320px;
        width: 100%;
        font-size: 1.6rem;
      }
      @media screen and (max-width: 375px) {
        font-size: 1.4rem;
      }
      &:after {
        content: '';
        position: absolute;
        background-image: url("../img/price-icon.svg");
        background-size: contain;
        background-repeat: no-repeat;
        width: 21px;
        height: 21px;
        top: 50%;
        left: 50px;
        transform: translate(0, -50%);
      }
      &:hover {
        background-color: $hover-color;
        border: 2px solid $hover-color;
      }
      &:focus {
        background-color: $red-color;
        border: 2px solid $red-color;
        color: $white-color;
      }
    }
  }
}

.form {
  padding: 70px 0 35px;
  @media screen and (max-width: 1199px) {
    padding: 50px 0 25px;
  }

  .container {
    @media screen and (max-width: 1199px) {
      padding: 0;
    }
    @media screen and (max-width: 767px) {
      padding: 0 20px;
    }
  }


  &__block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 45px 90px 80px;
    border-radius: 10px;
    background-color: $card-bg;
    @media screen and (max-width: 1199px) {
      max-width: 790px;
      padding: 70px 25px;
      margin: 0 auto;
    }
    @media screen and (max-width: 767px) {
      max-width: 425px;
      padding: 25px 20px;
    }
  }

  &__bg {
    display: block;
    width: 310px;
    height: 320px;
    object-fit: contain;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  &__wrapper {
    max-width: 705px;

    .title {
      max-width: 420px;
      text-transform: uppercase;
      letter-spacing: -0.05em;
      @media screen and (max-width: 767px) {
        font-size: 3.2rem;
      }
    }

    .subtitle {
      display: block;
      max-width: 420px;
      margin: 12px 0 40px;
      font-size: 2rem;
      line-height: 2.4rem;
      @media screen and (max-width: 767px) {
        font-size: 1.8rem;
      }
    }
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    height: 215px;
    @media screen and (max-width: 1199px) {
      flex-wrap: nowrap;
      flex-direction: column;
      height: 455px;
    }
    @media screen and (max-width: 767px) {
      align-items: center;
    }
  }

  .input-wrap {
    @media screen and (max-width: 1199px) {
      order: 1;
    }
  }

  .upload-btn {
    @media screen and (max-width: 1199px) {
      order: 2;
    }
  }


  .form-submit {
    @media screen and (max-width: 1199px) {
      order: 3;
    }
  }
}

.about {
  padding: 35px 0 100px;
  @media screen and (max-width: 1199px) {
    padding: 25px 0 90px;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: end;
    @media screen and (max-width: 1199px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__desc {
    flex-basis: 45.9%;
    @media screen and (max-width: 767px) {
      flex-basis: auto;
    }
    .desc-subtitle {
      font-size: 1.8rem;
      color: rgba(0, 0, 0, 0.4);
    }

    .title {
      margin: 20px 0 50px;
      text-transform: uppercase;
      font-weight: 500;
    }
  }

  &__desc-list {

    .desc-item {
      position: relative;
      padding-left: 40px;
      margin-bottom: 20px;
      font-size: 2rem;
      line-height: 2.4rem;
      @media screen and (max-width: 767px) {
        font-size: 1.8rem;
        line-height: 2rem;
      }
      &:before {
        content: '';
        position: absolute;
        display: block;
        width: 12px;
        height: 12px;
        top: 7px;
        left: 0;
        border-radius: 50%;
        background-color: $red-color;
      }
      &:after {
        content: '';
        position: absolute;
        width: 2px;
        height: 100%;
        top: 23px;
        left: 5px;
        background-color: $red-color;
      }
      &:first-child {
        transition: opacity 1s;
      }

      &:nth-child(2) {
        transition: opacity 5s;
      }
      &:last-child {
        margin-bottom: 0;
        transition: opacity 10s;
        &:after {
          display: none;
        }
      }
    }

    .assign {
      color: $red-color;
    }
  }

  &__map {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 45.9%;
    @media screen and (max-width: 1199px) {
      flex-basis: auto;
      width: 100%;
      margin-top: 50px;
    }
  }

  &__map-wrapper {
    position: relative;
    height: 425px;
    width: 560px;
    @media screen and (max-width: 767px) {
      max-width: 340px;
      width: 100%;
      height: 260px;
    }
    @media screen and (max-width: 375px) {
      max-width: 265px;
      height: 200px;
    }
    &:before {
      content: '';
      position: absolute;
      background-image: url("../img/map-line.svg");
      background-size: contain;
      background-repeat: no-repeat;
      width: 416px;
      height: 203px;
      top: 57.5%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
      @media screen and (max-width: 767px) {
        width: 260px;
        height: 130px;
      }
      @media screen and (max-width: 375px) {
        width: 200px;
        height: 115px;
        top: 59.5%;
      }
    }

    .map-item {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 150px;
      height: 86px;
      padding: 0 30px;
      border-radius: 5px;
      background-color: $map-item;
      font-size: 1.6rem;
      line-height: 2.4rem;
      text-align: center;
      @media screen and (max-width: 767px) {
        width: 90px;
        height: 51px;
        font-size: 0.9rem;
        line-height: 1.4rem;
      }
      @media screen and (max-width: 375px) {
        width: 70px;
        height: 40px;
      }

      &:first-child {
        width: 245px;
        height: 145px;
        padding: 0 80px;
        left: 50%;
        top: 0;
        transform: translate(-50%, 0);
        @media screen and (max-width: 767px) {
          width: 147px;
          height: 85px;
        }
        @media screen and (max-width: 375px) {
          width: 120px;
          height: 60px;
          padding: 0 20px;
        }
        &:before,
        &:after {
          content: '';
          position: absolute;
          background-size: contain;
          background-repeat: no-repeat;
          width: 50px;
          height: 80px;
          top: 50%;
          transform: translate(0, -50%);
          @media screen and (max-width: 375px) {
            width: 25px;
            height: 37px;
          }
        }


        &:before {
          background-image: url("../img/wing-left.svg");
          left: 30px;
          @media screen and (max-width: 375px) {
            left: 5px;
          }
        }

        &:after {
          background-image: url("../img/wing-right.svg");
          right: 30px;
          @media screen and (max-width: 375px) {
            right: 5px;
          }
        }
      }

      &:nth-child(2) {
        top: 50%;
        left: 0;
      }

      &:nth-child(3) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, 0);
      }

      &:nth-child(4) {
        top: 50%;
        right: 0;
      }

      &:nth-child(5) {
        bottom: 0;
        left: 19%;
      }
      &:nth-child(6) {
        bottom: 0;
        right: 19%;
      }
    }
  }

  .map-name {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 42px;
    margin-top: 65px;
    border-radius: 3px;
    background-color: #F9F9F9;
    font-size: 1.8rem;
    color: rgba(0, 0, 0, 0.4);
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
      margin-top: 40px;
    }
  }
}

.certificate {
  padding: 70px 0 90px;
  background-color: $section-bg;
  @media screen and (max-width: 767px) {
    padding: 50px 0;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__text {
    width: 40%;
    @media screen and (max-width: 1199px) {
      width: 50%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      max-height: 425px;
    }

    .subtitle {
      font-size: 1.8rem;
      line-height: 4rem;
      color: rgba(255, 255, 255, 0.4);
    }

    .title {
      margin: 15px 0 40px;
      font-size: 3.2rem;
      line-height: 4rem;
      color: $white-color;
      @media screen and (max-width: 1199px) {
        font-size: 3rem;
      }
      @media screen and (max-width: 767px) {
        font-size: 2.8rem;
      }
    }
  }

  .slider-navigation {
    display: flex;
    align-items: center;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  &__btn {
    display: flex;
    justify-content: space-between;
    width: 130px;
  }

  .certificate-pagination {
    width: fit-content;
    margin-left: 30px;
    font-size: 2rem;
    color: $white-color;
  }

  &__slider {
    position: relative;
    max-width: 840px;
    width: 60%;
    height: 305px;
    overflow: hidden;
    &:before {
      content: '';
      position: absolute;
      width: 246px;
      height: 430px;
      left: -110px;
      top: 50%;
      transform: translate(0, -50%) rotate(-180deg);
      background: linear-gradient(270deg, #303138 65.78%, rgba(48, 49, 56, 0) 100%);
      z-index: 5;
      @media screen and (max-width: 425px) {
        left: -150px;
      }
    }
    &:after {
      content: '';
      position: absolute;
      width: 246px;
      height: 430px;
      right: -110px;
      top: 50%;
      transform: translate(0, -50%);
      background: linear-gradient(270deg, #303138 65.78%, rgba(48, 49, 56, 0) 100%);
      z-index: 5;
      @media screen and (max-width: 425px) {
        right: -150px;
      }
    }
    @media screen and (max-width: 1199px) {
      width: 50%;
      height: 205px;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &__slider-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 975px;
    height: 100%;
    transform: translate(-50%, -50%);
    @media screen and (max-width: 1199px) {
      width: 480px;
    }
  }

  .slider-item {
    position: relative;
    display: block;
    width: 225px;
    height: 100%;
    padding: 5px;
    background-color: $white-color;
    box-shadow: 0 4px 34px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    cursor: pointer;
    @media screen and (max-width: 1199px) {
      width: 150px;
      height: 205px;
      border-radius: 6px;
    }
  }
  .slider-img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.description {
  position: relative;
  padding: 65px 0;
  @media screen and (max-width: 767px) {
    padding-top: 35px;
    padding-bottom: 0;
  }

  &:after {
    content: '';
    position: absolute;
    background-image: url("../img/bg-desc.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: bottom;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    z-index: -5;
    @media screen and (max-width: 1199px) {
      background-image: url("../img/bg-desc-moble.png");
    }
  }

  .subtitle {
    font-size: 1.8rem;
    color: rgba(29, 29, 29, 0.4);
    @media screen and (max-width: 1199px) {
      display: none;
    }
    @media screen and (max-width: 767px) {
      display: block;
    }
  }

  .title {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 4.8rem;
    letter-spacing: -0.05em;
    @media screen and (max-width: 1199px) {
      display: none;
    }
    @media screen and (max-width: 767px) {
      display: block;
      margin-bottom: 25px;
      font-size: 3.2rem;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1199px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__card {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-basis: 45%;
    margin-top: 55px;
    @media screen and (max-width: 1199px) {
      flex-basis: auto;
      max-width: 700px;
    }
    @media screen and (max-width: 767px) {
      order: 2;
      margin-top: 70px;
    }

    .card-item {
      display: flex;
      flex-direction: column;
      max-width: 250px;
      margin-bottom: 30px;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    .card-icon {
      width: 48px;
      height: 48px;
      margin-bottom: 15px;
      object-fit: contain;
    }

    .card-text {
      font-size: 2rem;
      line-height: 2.4rem;
      @media screen and (max-width: 767px) {
        font-size: 1.8rem;
      }
    }
  }

  &__text {
    flex-basis: 45%;
    height: fit-content;
    margin-top: 20px;
    padding: 50px 60px 80px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.5);
    @media screen and (max-width: 1199px) {
      flex-basis: auto;
      max-width: 700px;
      padding: 45px 35px;
      margin-top: 70px;
    }
    @media screen and (max-width: 767px) {
      order: 1;
      padding: 0;
      margin-top: 0;
    }
  }

  .paragraph {
    margin-bottom: 20px;
    font-size: 2rem;
    line-height: 2.4rem;
    letter-spacing: -0.01em;
    &:last-child {
      margin-bottom: 0;
    }

    &:nth-child(3) {
      font-weight: 600;
    }
    @media screen and (max-width: 767px) {
      font-size: 1.8rem;
    }
  }
}

.counter {
  padding: 85px 0;
  @media screen and (max-width: 1199px) {
    padding: 60px 0;
  }

  &__wrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    opacity: 0;
    transition: .3s opacity;
    @media screen and (max-width: 1199px) {
      flex-wrap: wrap;
      max-width: 560px;
      margin: 0 auto;
    }
    @media screen and (max-width: 767px) {
      flex-wrap: nowrap;
      flex-direction: column;
    }

    &.show-counter {
      opacity: 1;
    }
  }

  .counter-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 1199px) {
      width: 270px;
      margin-bottom: 25px;
      &:last-child {
        margin:  0 auto;
      }
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }

    &:after {
      content: '';
      position: absolute;
      height: 140px;
      width: 1px;
      top: 0;
      right: -40px;
      background-color: rgba(0, 0, 0, 0.11);
      transform: rotate(15deg);
      @media screen and (max-width: 1199px) {
        display: none;
      }
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    .plus {
      opacity: 0;
      transition: .3s opacity;
    }
  }

  .num-wrap,
  .counter-num {
    position: relative;
    font-size: 8rem;
    color: $red-color;
    @media screen and (max-width: 767px) {
      font-size: 6.4rem;
    }
  }

  .counter-desc {
    width: 170px;
    font-size: 2rem;
    text-align: center;
    @media screen and (max-width: 767px) {
      font-size: 1.8rem;
    }
  }
}

.clients {
  padding: 70px 0 90px;
  @media screen and (max-width: 767px) {
    padding: 50px 0 40px;
  }
  .container {
    @media screen and (max-width: 1199px) {
      padding: 0;
    }
  }

  &__wrapper {
    width: 100%;
    max-width: 1330px;
    padding: 55px 60px 30px;
    margin: 0 auto;
    background: linear-gradient(0deg, #F5F6FB, #F5F6FB), #575963;
    border-radius: 8px;
    @media screen and (max-width: 1199px) {
      padding: 60px 50px 30px;
    }
    @media screen and (max-width: 767px) {
      padding: 60px 0 30px;
    }
    .title {
      @media screen and (max-width: 767px) {
        margin-left: 25px;
        font-size: 3.2rem;
      }
    }
  }

  &__slider {
    position: relative;
    width: 100%;
    height: 160px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    overflow: hidden;
    @media screen and (max-width: 767px) {
      height: 350px;
    }
    @media screen and (max-width: 767px) {
      height: 215px;
      margin-top: 40px;
    }
    &:before {
      content: '';
      position: absolute;
      width: 160px;
      height: 100%;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      background: linear-gradient(90deg, #F5F6FB 29.67%, rgba(245, 246, 251, 0) 100%);
      z-index: 5;
    }
    &:after {
      content: '';
      position: absolute;
      width: 160px;
      height: 160px;
      right: 0;
      top: 50%;
      transform: translate(0, -50%) rotate(-180deg);
      background: linear-gradient(90deg, #F5F6FB 29.67%, rgba(245, 246, 251, 0) 100%);
      z-index: 5;
    }
    &:before,
    &:after {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }

  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 160px;
    padding: 60px 30px;
    @media screen and (max-width: 1199px) {
      padding: 50px 25px;
    }
    @media screen and (max-width: 767px) {
      height: 100px;
      padding: 30px 15px;
    }
  }

  .client-logo {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .client-logo {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .clients-pagination {
    width: fit-content;
    margin: 70px auto 0;
    @media screen and (max-width: 1199px) {
      margin-top: 50px;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }

    .swiper-pagination-bullet {
      width: 16px;
      height: 16px;
      background-color: transparent;
      border: 1px solid $text-color;
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      background-color: $text-color;
    }
  }

  .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    width: fit-content;
  }
}

.projects {
  padding: 60px 0 70px;
  color: $white-color;
  background-color: $section-bg;
  @media screen and (max-width: 767px) {
    padding: 50px 20px 25px;
  }

  .title {
    margin-bottom: 22px;
    @media screen and (max-width: 767px) {
      font-size: 3.2rem;
      line-height: 4rem;
      margin-bottom: 20px;
    }
  }

  &__slider {
    position: relative;
    max-width: 1155px;
    width: 100%;
    @media screen and (max-width: 767px) {

    }
  }

  .slide-item {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      max-width: 425px;
      margin: 0 auto;
    }
  }

  .slide-img {
    width: 100%;
    height: 320px;
    border-radius: 4px;
    object-fit: cover;
    @media screen and (max-width: 1199px) {
      height: 235px;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 100px;
    }

  }

  .slide-left,
  .slide-right {
    width: 47.2%;
  }

  .slide-left {
    @media screen and (max-width: 1199px) {
      width: 54%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .slide-right {
    @media screen and (max-width: 1199px) {
      width: 42%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }


  .title-desktop {
    font-size: 2.8rem;
    line-height: 3.2rem;
    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
  }

  .text-block {
    position: relative;
    margin-bottom: 60px;
    @media screen and (max-width: 767px) {
      max-height: 40px;
      margin-bottom: 20px;
      overflow: hidden;
      cursor: pointer;
      transition: .3s;
      &:after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        top: 13px;
        right: 10px;
        border-bottom: 2px solid $white-color;
        border-right: 2px solid $white-color;
        transform: rotate(45deg);
        transition: .3s;
      }
    }
    &:before {
      content: '';
      position: absolute;
      width: 120px;
      height: 2px;
      left: 0;
      bottom: -30px;
      background-color: $red-color;
    }

    &:last-child {
      margin-bottom: 0;
      &:before {
        display: none;
      }
    }

    &.active {
      max-height: 2000px;
      &:after {
        transform: rotate(225deg);
      }
    }
  }

  .subtitle {
    position: relative;
    display: block;
    font-size: 2.4rem;
    line-height: 4rem;
  }

  .task-paragraph,
  .solution-paragraph {
    font-size: 2rem;
    line-height: 2.4rem;
    @media screen and (max-width: 767px) {
      font-size: 1.8rem;
      line-height: 2rem;
    }
  }

  .projects-navigation {
    position: absolute;
    display: flex;
    align-items: center;
    max-width: 545px;
    width: 100%;
    left: 0;
    top: 375px;
    z-index: 10;
    @media screen and (max-width: 1199px) {
      align-items: start;
      flex-direction: column;
      max-width: 395px;
      top: 305px;
    }
    @media screen and (max-width: 767px) {
      max-width: 100%;
      top: 260px;
    }
  }

  &__btn-slider {
    display: flex;
    align-items: center;
    @media screen and (max-width: 767px) {
      width: 100%;
      justify-content: space-between;
    }
  }

  .projects-button-prev {
    margin-right: 15px;
  }

  .projects-pagination {
    width: fit-content;
    margin-left: 30px;
    font-size: 2rem;
    line-height: 2.4rem;
    @media screen and (max-width: 767px) {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      margin: 0;
    }
  }

  .projects-btn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    width: 290px;
    height: 56px;
    top: 375px;
    left: 220px;
    text-transform: uppercase;
    z-index: 10;
    @media screen and (max-width: 1199px) {
      width: 390px;
      left: 0;
    }
    @media screen and (max-width: 767px) {
      position: relative;
      top: 100%;
      max-width: 390px;
      width: 100%;
      margin: 30px auto 0;
    }
  }

}


.contacts {
  padding-top: 120px;
  padding-bottom: 72px;
  @media screen and (max-width: 1199px) {
    padding-top: 70px;
    padding-bottom: 32px;
  }
  @media screen and (max-width: 767px) {
    padding: 45px 0;
  }
  &__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media screen and (max-width: 1199px) {
      flex-wrap: wrap;
      max-width: 800px;
      margin: 0 auto;
    }
    @media screen and (max-width: 767px) {
      max-width: 425px;
    }
    @media screen and (max-width: 375px) {
      flex-direction: column;
    }

  }

  &__address {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    width: 100%;
    @media screen and (max-width: 1199px) {
      margin-bottom: 30px;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
  }

  .address-title {
    margin-bottom: 25px;
    font-size: 2rem;
    line-height: 2.4rem;
    font-weight: 600;
  }

  .address-item {
    margin-bottom: 20px;
    font-size: 1.8rem;
    line-height: 2.4rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__resources {
    display: flex;
    flex-direction: column;
    max-width: 200px;
    width: 100%;
    @media screen and (max-width: 767px) {
      margin-bottom: 40px;
    }
    @media screen and (max-width: 425px) {
      margin-bottom: 20px;
    }
  }

  .resources-title {
    margin-bottom: 25px;
    font-size: 2rem;
    line-height: 2.4rem;
    font-weight: 600;
  }

  .resources-item {
    position: relative;
    padding-left: 25px;
    margin-bottom: 20px;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: $red-color;
    transition: .3s;
    &:last-child {
      margin-bottom: 0;
    }
    &:before {
      content: '';
      position: absolute;
      background-image: url("../img/globe.svg");
      background-size: contain;
      background-repeat: no-repeat;
      width: 16px;
      height: 16px;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      transition: .3s;
    }
    &:hover {
      color: $text-color;
      &:before {
        background-image: url("../img/globe-hover.svg");
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  .contacts-title {
    margin-bottom: 25px;
    font-size: 2rem;
    line-height: 2.4rem;
    font-weight: 600;
  }

  .contacts-link {
    margin-bottom: 20px;
    font-size: 1.8rem;
    line-height: 2.4rem;
    transition: .3s;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      color: $red-color;
    }
  }

  .contacts-social {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .social-item {
    position: relative;
    display: block;
    width: 24px;
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
    transition: .3s;
  }

  .contacts-telegram {
    background-image: url("../img/telegram-black.svg");
    &:hover {
      background-image: url("../img/telegram-hover.svg");
    }
  }

  .contacts-viber {
    background-image: url("../img/viber-black.svg");
    &:hover {
      background-image: url("../img/viber-hover.svg");
    }
  }

  .contacts-whatsapp {
    background-image: url("../img/whatsapp-black.svg");
    &:hover {
      background-image: url("../img/whatsapp-hover.svg");
    }
  }

  .contacts-mail {
    background-image: url("../img/mail-black.svg");
    &:hover {
      background-image: url("../img/mail-hover.svg");
    }
  }

  .navigate-desktop {
    display: none;
    @media screen and (max-width: 1199px) {
      display: block;
      max-width: 290px;
      width: 100%;
      height: 105px;
      margin: auto 0;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .menu {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
  }
  .menu-item {
    height: fit-content;
  }
  .menu-link {
    color: $text-color;
  }

  &__callback {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 440px;
    width: 100%;
    padding: 25px 30px 30px;
    margin-top: -20px;
    background-color: $card-bg;
    @media screen and (max-width: 1199px) {
      max-width: 380px;
      margin-top: 0;
      padding: 25px 20px 20px;
    }
    @media screen and (max-width: 767px) {
      max-width: 100%;
      padding: 25px 15px 20px;
    }
    @media screen and (max-width: 425px) {
      margin-top: 40px;
    }

    .callback-img {
      position: absolute;
      top: 25px;
      left: 30px;
      width: 72px;
      height: 72px;
      border-radius: 50%;
      object-fit: cover;
      object-position: top;
      background-color: #303138;
      @media screen and (max-width: 425px) {
        left: 15px;
      }
    }
  }

  .callback-title {
    max-width: 240px;
    margin-bottom: 10px;
    margin-left: 100px;
    font-size: 2rem;
    line-height: 2.4rem;
    font-weight: 600;
    @media screen and (max-width: 425px) {
      max-width: 180px;
      font-size: 1.8rem;
    }
    @media screen and (max-width: 375px) {
      max-width: 170px;
      font-size: 1.4rem;
    }
  }

  .callback-subtitle {
    max-width: 240px;
    margin-left: 100px;
    font-size: 2rem;
    line-height: 2.4rem;
    @media screen and (max-width: 425px) {
      max-width: 180px;
      font-size: 1.6rem;
    }
    @media screen and (max-width: 375px) {
      max-width: 170px;
      font-size: 1.4rem;
    }
  }

  .callback-btn {
    height: 56px;
    margin-top: 30px;
  }

}

.swiper_dublicate {
  display: none;
}