@import "color.scss";

.modal-bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(60px);
  display: none;
  z-index: 100;
  &.active {
    display: block;
  }
}

.modal {
  width: calc(100% - 40px);
  max-width: 470px;
  max-height: 80vh;
  overflow-y: auto;
  padding: 80px 32px 30px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  border-radius: 6px;
  background-color: $white-color;
  transition: .3s;
  z-index: 110;
  &.active {
    transform: translate(-50%, -50%) scale(1);
  }
  @media screen and (max-width: 767px) {
    padding: 80px 20px 20px;
  }

  .modal-close {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 32px;
    right: 32px;
    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 16px;
      height: 2px;
      top: 50%;
      left: 50%;
      background-color: $text-color;
    }
    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    @media screen and (max-width: 767px) {
      top: 20px;
      right: 15px;
    }
  }

  .title {
    font-size: 2.8rem;
    text-transform: unset;
    margin-bottom: 24px;
    @media screen and (max-width: 767px) {
      font-size: 2.6rem;
    }
  }

  .input-wrap {
    max-width: 100%;
    margin-bottom: 24px;
  }

  .input-label {
    background-color: $white-color;
  }

  .submit {
    width: 100%;
    height: 56px;
  }
}

.modal-upload {


  .upload-btn {
    max-width: 100%;
    margin-bottom: 24px;
  }
}

.modal-thanks,
.modal-error {

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    text-align: center;
  }

  .img-wrap {
    width: 86px;
    height: 86px;
    padding: 7px;
    margin-bottom: 25px;
    border-radius: 50%;
    border: 3px solid $red-color;
    overflow: hidden;
  }

  &__img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: contain;
    background-color: #303138;
  }

  &__text {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 25px;
    font-size: 2rem;
    line-height: 2.4rem;
  }

}

.modal-error {

  .img-wrap {
    position: relative;
    &:before {
      content: '!';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 6rem;
      color: $red-color;
    }
  }
}

.modal-services {
  max-width: 790px;
  overflow-y: hidden;

  &__list-wrapper {
    max-height: 30vh;
    margin-top: 25px;
    overflow-y: auto;
    @media screen and (max-width: 767px) {
      margin-top: 60px;
      font-size: 1.8rem;
    }
  }

  &__list {
    height: 100%;
    overflow: hidden;
    @media screen and (max-width: 767px) {
      font-size: 1.8rem;
    }
  }

  .list-item {
    position: relative;
    padding-left: 30px;
    margin-bottom: 20px;
    font-size: 2rem;
    line-height: 2.4rem;
    @media screen and (max-width: 767px) {
      font-size: 1.8rem;
    }
    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 12px;
      height: 12px;
      top: 5px;
      left: 0;
      border-radius: 50%;
      background-color: $red-color;
    }
    &:after {
      content: '';
      position: absolute;
      width: 2px;
      height: 100%;
      top: 20px;
      left: 5px;
      background-color: $red-color;
    }
    &:last-child {
      margin-bottom: 0;
      &:after {
        display: none;
      }
    }
  }

  .list-subtitle {
    font-weight: 600;
  }
}