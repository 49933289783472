@import "color.scss";

@font-face {
  font-family: 'FF Unit Pro Regular';
  font-style: normal;
  font-weight: 300;
  src: local('FF Unit Pro Regular'), url('../font/FF Unit Pro Regular.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Pro Italic';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Pro Italic'), url('../font/FF Unit Pro Italic.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Pro Thin';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Pro Thin'), url('../font/FF Unit Pro Thin.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Pro Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Pro Thin Italic'), url('../font/FF Unit Pro Thin Italic.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Pro Light';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Pro Light'), url('../font/FF Unit Pro Light.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Pro Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Pro Light Italic'), url('../font/FF Unit Pro Light Italic.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Pro Medium';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Pro Medium'), url('../font/FF Unit Pro Medium.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Pro Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Pro Medium Italic'), url('../font/FF Unit Pro Medium Italic.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Pro Bold';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Pro Bold'), url('../font/FF Unit Pro Bold.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Pro Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Pro Bold Italic'), url('../font/FF Unit Pro Bold Italic.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Pro Black';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Pro Black'), url('../font/FF Unit Pro Black.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Pro Ultra';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Pro Ultra'), url('../font/FF Unit Pro Ultra.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Pro Black Italic';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Pro Black Italic'), url('../font/FF Unit Pro Black Italic.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Pro Ultra Italic';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Pro Ultra Italic'), url('../font/FF Unit Pro Ultra Italic.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Rounded Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Rounded Pro Regular'), url('../font/FF Unit Rounded Pro Regular.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Slab Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Slab Pro Regular'), url('../font/FF Unit Slab Pro Regular.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Slab Pro Italic';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Slab Pro Italic'), url('../font/FF Unit Slab Pro Italic.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Slab Pro Thin';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Slab Pro Thin'), url('../font/FF Unit Slab Pro Thin.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Slab Pro Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Slab Pro Thin Italic'), url('../font/FF Unit Slab Pro Thin Italic.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Rounded Pro Light';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Rounded Pro Light'), url('../font/FF Unit Rounded Pro Light.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Slab Pro Light';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Slab Pro Light'), url('../font/FF Unit Slab Pro Light.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Slab Pro Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Slab Pro Light Italic'), url('../font/FF Unit Slab Pro Light Italic.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Rounded Pro Medium';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Rounded Pro Medium'), url('../font/FF Unit Rounded Pro Medium.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Slab Pro Medium';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Slab Pro Medium'), url('../font/FF Unit Slab Pro Medium.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Slab Pro Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Slab Pro Medium Italic'), url('../font/FF Unit Slab Pro Medium Italic.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Rounded Pro Bold';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Rounded Pro Bold'), url('../font/FF Unit Rounded Pro Bold.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Slab Pro Bold';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Slab Pro Bold'), url('../font/FF Unit Slab Pro Bold.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Slab Pro Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Slab Pro Bold Italic'), url('../font/FF Unit Slab Pro Bold Italic.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Rounded Pro Black';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Rounded Pro Black'), url('../font/FF Unit Rounded Pro Black.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Rounded Pro Ultra';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Rounded Pro Ultra'), url('../font/FF Unit Rounded Pro Ultra.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Slab Pro Black';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Slab Pro Black'), url('../font/FF Unit Slab Pro Black.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Slab Pro Ultra';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Slab Pro Ultra'), url('../font/FF Unit Slab Pro Ultra.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Slab Pro Black Italic';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Slab Pro Black Italic'), url('../font/FF Unit Slab Pro Black Italic.woff') format('woff');
}


@font-face {
  font-family: 'FF Unit Slab Pro Ultra Italic';
  font-style: normal;
  font-weight: normal;
  src: local('FF Unit Slab Pro Ultra Italic'), url('../font/FF Unit Slab Pro Ultra Italic.woff') format('woff');
}