@import "color.scss";

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;

  &__headline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 0;

  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 240px;
    height: 56px;
    padding: 0 24px;
    text-transform: uppercase;
  }

  &__btn-desktop {
    @media screen and (max-width: 767px) {
      display: none;
    }

    .btn-icon {
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
  }

  &__menu {
    display: flex;
    justify-content: space-between;
    padding: 9px 0;
    @media screen and (max-width: 767px) {
      position: absolute;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 0;
      top: 0;
      left: 0;
      padding: 0;
      background-color: $white-color;
      overflow: hidden;
      transition: .3s;
      &.active {
        height: 100vh;
      }
    }
  }

  &__btn-mobile {
    display: none;
    @media screen and (max-width: 767px) {
      display: flex;
    }
  }
  .navigate {
    height: fit-content;
    width: 600px;
    margin: auto 0 10px;
    @media screen and (max-width: 1400px) {
      width: 480px;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      margin: auto 0;
    }
  }

  .menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      margin-top: 120px;
      margin-bottom: 60px;
    }
  }

  &__contacts {
    @media screen and (max-width: 767px) {
      margin-top: 40px;
      margin-bottom: 75px;
      max-width: 190px;
    }
    .phone {
      text-transform: uppercase;
      font-size: 2.2rem;
      line-height: 1.8rem;
      color: $white-color;
      transition: .3s;
      &:hover {
        color: $red-color;
      }
      @media screen and (max-width: 767px) {
        color: $text-color;
      }
    }

    .social {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .social-link {
      display: block;
      width: 23px;
      height: 23px;
      margin-top: 16px;
      background-size: contain;
      background-repeat: no-repeat;
      transition: .3s;
    }

    .header-insta {
      background-image: url("../img/insta.svg");
      &:hover {
        background-image: url("../img/insta-hover.svg");
      }
      @media screen and (max-width: 767px) {
        background-image: url("../img/insta-black.svg");
      }
    }

    .header-telegram {
      background-image: url("../img/telegram.svg");
      &:hover {
        background-image: url("../img/telegram-hover.svg");
      }
      @media screen and (max-width: 767px) {
        background-image: url("../img/telegram-black.svg");
      }
    }

    .header-viber {
      background-image: url("../img/viber.svg");
      &:hover {
        background-image: url("../img/viber-hover.svg");
      }
      @media screen and (max-width: 767px) {
        background-image: url("../img/viber-black.svg");
      }
    }

    .header-whatsapp {
      background-image: url("../img/whatsapp.svg");
      &:hover {
        background-image: url("../img/whatsapp-hover.svg");
      }
      @media screen and (max-width: 767px) {
        background-image: url("../img/whatsapp-black.svg");
      }
    }

    .header-mail {
      background-image: url("../img/mail.svg");
      &:hover {
        background-image: url("../img/mail-hover.svg");
      }
      @media screen and (max-width: 767px) {
        background-image: url("../img/mail-black.svg");
      }
    }
  }

  &__btn-menu {
    position: absolute;
    display: none;
    width: 24px;
    height: 24px;
    top: 30px;
    right: 30px;
    @media screen and (max-width: 767px) {
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 20;
    }
    .line {
      display: block;
      width: 18px;
      height: 2px;
      background-color: $white-color;
      transition: .3s;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 18px;
      height: 2px;
      left: 50%;
      transform: translate(-50%, 0);
      background-color: $white-color;
      transition: .3s;
    }

    &:before {
      top: 5px;
    }

    &:after {
      bottom: 5px;
    }

    &.active {
      .line {
        display: none;
      }
      &:before {
        top: 11px;
        background-color: $text-color;
        transform: translate(-50%, 0) rotate(45deg);
      }

      &:after {
        bottom: 11px;
        background-color: $text-color;
        transform: translate(-50%, 0) rotate(-45deg);
      }
    }

  }
}

